<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="block__tabs mb-30">
    <VTabs :is-small="true" :items="bidderMyAuctions"/>
  </div>
  <div class="block__control">
    <div class="block__field">
      <VSearch
        name="searchAdmin"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />

      <VFilter
        @onFilter="onSubmit"
        @onReset="onReset"
      >
        <template v-slot:default>
          <VDateInput
            class="mb-40"
            name="CreatedAt"
            placeholder="Дата создания"
            v-model="dateCreate"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="StartedAt"
            placeholder="Дата начала"
            v-model="dateStart"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="PublishedAt"
            placeholder="Дата публикации"
            v-model="datePublished"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="Duration"
            placeholder="Дата окончания"
            v-model="dateDuration"
            @input="onDateInput"
          />
          <VSelect
            v-if="visibleTradeFilterUsers"
            v-model="filterFormData.CompanyId"
            placeholder="Компания"
            name="CompanyId"
            :options="filterOptions.companies"
          />
          <VSelect
            v-if="visibleTradeFilterUsers"
            v-model="filterFormData.ResponsibleFullName"
            placeholder="Организатор"
            name="ResponsibleFullName"
            :options="filterOptions.organizers"
          />
        </template>
      </VFilter>
    </div>
  </div>
  <router-view

  ></router-view>
</template>

<script>
import VSearch from '@/components/ui/form/Search/VSearch'
import { bidderMyAuctions } from '@/utils/tabs'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VDateInput from '@/components/ui/form/VDateInput'
import { computed, ref } from 'vue'
import VTabs from '@/components/views/Tabs/VTabs'
import { itemProps } from '@/mixin/props'
import { useFilter } from '@/use/Base/useFilter'
import { useTradeFilterUsers } from '@/use/trade/useTradeFilterUsers'
import VSelect from '@/components/ui/form/VSelect.vue'
import { useRoute } from 'vue-router'

export default {
  mixins: [itemProps],
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const dateCreate = ref(null)
    const datePublished = ref(null)
    const dateDuration = ref(null)
    const dateStart = ref(null)
    const dateInput = []

    const onDateInput = (name, value) => {
      dateInput.push([name, value])
    }

    const route = useRoute()
    const visibleTradeFilterUsers = computed(() => route.name !== 'bidder-my-auctions-protocols')

    const {
      filterFormData,
      filterOptions,
      resetFilter,
      fillFilter
    } = useTradeFilterUsers(
      {
        hasArchive: true
      },
      {
        'search[AsParticipant]': ''
      }
    )

    const filter = () => {
      const filter = []
      for (let i = 0; i < dateInput.length; i++) {
        const filterName = dateInput[i][0]
        const date = dateInput[i][1].toISOString().split('T')
        filter.push({ type: filterName, value: date[0] })
      }
      fillFilter(filter)
      return filter
    }

    const reset = () => {
      dateCreate.value = null
      datePublished.value = null
      dateDuration.value = null
      dateStart.value = null
      resetFilter()
    }

    return {
      onDateInput,
      dateCreate,
      datePublished,
      dateStart,
      dateDuration,
      ...useFilter(filter, reset),
      bidderMyAuctions,
      filterFormData,
      filterOptions,
      visibleTradeFilterUsers
    }
  },
  components: {
    VSelect,
    VSearch,
    VTabs,
    VFilter,
    VDateInput
  }
}
</script>
